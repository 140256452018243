@import '../../main.module.scss';

.tags-container {
  display: block;
  padding-top: 66px;

  &--disabled {
    padding-top: 0;
  }
}

.length-info {
  color: #70707a;
  font-size: $small-text-size;
}

:global(.ReactTags__tags) {
  position: relative;
  word-break: break-all;
}

:global(.ReactTags__tagInput) {
  border-radius: 2px;
  display: block;
  width: 300px;

  @include breakpoint(tablet-and-down) {
    width: initial;
  }
}

.close-icon {
  cursor: pointer;
  padding-left: 10px;
  position: relative;
  top: 4px;
  width: 2em !important;
}

:global(.ReactTags__tagInput .ReactTags__tagInputField),
:global(.ReactTags__tagInput .ReactTags__tagInputField:focus) {
  background: $white;
  border: 1px solid $black;
  display: block;
  font-family: $default-font;
  font-size: $regular-text-size;
  height: 48px;
  line-height: 24px;
  margin-top: -66px;
  position: absolute;
  top: 0;
  width: 100%;

  @include breakpoint(tablet-and-down) {
    width: 100%;
  }
}

:global(.ReactTags__selected) {
  display: contents;
  margin-top: 66px;
  position: relative;
}

:global(.ReactTags__selected .ReactTags__tag) {
  background-color: $link-color;
  border-radius: 2px;
  color: $white;
  cursor: move;
  display: inline-block;
  font-family: $default-font;
  font-size: $small-text-size;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 28px;
  margin: 5px 10px 5px 0;
  padding: 6px 6px 6px 12px;
}
