@import '../../../main.module.scss';

.container {
  margin-left: -60px;

  & .add-button {
    margin-bottom: 40px;
    margin-top: 40px;
  }
}

.week-calendar {
  max-height: 70vh !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
