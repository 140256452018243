@import '../../../main.module.scss';

.info-row {
  padding-bottom: 37px;
  padding-top: 37px;
}

div.calendar-row {
  line-height: 24px;
}

.weekdayschedule {
  width: 860px;
  overflow: hidden;
}

.modal-content {
  padding: 25px;

  @include breakpoint(laptop-and-down) {
    max-height: 100vh !important;
  }
}
